import React from 'react';
import { NavLink } from 'react-router-dom';
import { getServices } from '../../ServicesList';
import Innovations from "./Innovations";
const Footer = () => {
    const Year = new Date().getFullYear();
    const services = getServices();
    return (
        // <!-- ======= Footer ======= -->
        <footer id="footer">
            <div className="footer-newsletter mx-auto">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <h1>Nos innovations</h1>
                        </div>
                    </div>
                </div>
                <Innovations />
            </div>
            <div className="footer-top">
                <div className="container">
                    <div className="row mx-auto">
                        <div className="col-lg-3 col-md-4 footer-contact">
                            <h4>Wenovate</h4>
                            <p>
                                Bamako <br />
                                Mali<br />
                               <br />
                                <strong>Téléphone:</strong> +223 70 62 28 79<br />
                                <strong>Email:</strong> contact@wenovate.com<br />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-4 footer-links">
                            <h4>Liens utils</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a target={"_blank"} href="https://www.termsofservicegenerator.net/live.php?token=EyBHJSLw3wZMnBeIKzg6Jm9j2SC6x64W"> Conditions d’utilisation</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a target={"_blank"} href="https://www.termsfeed.com/live/eaf44bb1-ac16-4d5c-bcfc-6f2916a1e454"> Politique de confidentialité</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-4 footer-links">
                            <h4>Nos Services</h4>
                            <ul>
                                <li style={{color:'white'}}><i className="bx bx-chevron-right"></i> Infographie</li>
                                <li style={{color:'white'}}><i className="bx bx-chevron-right"></i> Formations</li>
                                <li style={{color:'white'}}><i className="bx bx-chevron-right"></i> Assistance technique</li>
                                {services.map(service => (
                                    <li key={service.name}><i className="bx bx-chevron-right"></i> <NavLink to={`/${service.name}`}>{service.name}</NavLink></li>
                                ))}
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Réseaux sociaux</h4>
                            <div className="social-links mt-3">
                                {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a> */}
                                <a href="https://www.facebook.com/Wenovate-108318985129007" className="facebook"><i className="bx bxl-facebook"></i></a>
                                <a href="https://www.instagram.com/wenovate_ml/" className="instagram"><i className="bx bxl-instagram"></i></a>
                                {/* <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                                <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container footer-bottom clearfix">
                <div className="copyright">
                    &copy; {Year} Copyright  <strong><span>Wenovate</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
        //* <!-- End Footer --> */

    );
}
export default Footer;