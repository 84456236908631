let servicesList = [
  {
    name: "Développement Web",
    mainImg: "./assets/img/devweb.png",
    mainText: "Wenovate crée des applications Web évolutives et interactives qui engagent les utilisateurs et alimentent les flux de travail critiques. Des atterrissages personnalisés d’une page aux systèmes complexes à l’échelle de l’entreprise, notre bilan comprend plus de 10 projets Web terminés, dont plus de 5 sont moyens et grands. Nous nous concentrons sur des partenariats à long terme qui sont prouvés par de nombreux clients du Mali, de la France, et du Maroc qui restent et grandissent avec nous depuis plus d'un an.",
    OfferText: "Nous offrons des services complèts de developpement web. De la spécification des besoins au déploiement de la solution en passant par les tests unitaires et utilisateurs",
    OImg: "12/05/1995",
    YearExp: "5+",
    WS1: [
      { text: "Amélioration des solutions WEB existante" },
      { text: "Conception WEB UI/UX" },
      { text: "Conseil et analyse d’affaires" },
      { text: "Assurance qualité et tests" },
      { text: "Développement de sites et d’applications WEB personnalisés" },
      { text: "Développement WEB SAAS" },
      { text: "Support et maintenance à long terme" },
      { text: "Développement et intégration d'API" },
      { text: "Développement intranet et extranet" }
    ],
    WS2: [
      { title: "Développement HTML5.", text: "HTML est largement utilisé dans le développement de sites Web. En utilisant HTML, nous créons du texte avec des liens et des hyperliens qui peuvent transformer des lettres en images, tableaux et autres représentations." },
      { title: "Développement JavaScript.", text: "Le développement JavaScript est utilisé pour transformer une page HTML en écosystème dynamique et interactif. Il donne à un développeur front-end la possibilité d’ajouter des fonctionnalités riches et des éléments interactifs tels que des cartes, des films ou des jeux en ligne au site Web." },
      { title: "Développement CSS3.", text: "En utilisant des feuilles de style et des effets CSS, nous créons un look unique de votre site Web." },
      "./assets/img/webdevws2.jfif"
    ],
    WS3: [
      { title: "Développement AJAX.", text: "AJAX est un format de transfert de données que nous utilisons pour créer des applications Web dynamiques." },
      { title: "Développement jQuery.", text: "Il s’agit d’une bibliothèque JavaScript – une collection de plugins et d’extensions qui aide à rationaliser le processus de développement. jQuery propose des éléments prêts à l’emploi qu’un développeur front-end peut personnaliser et utiliser dans son projet." },
      { title: "Développement AngularJS, Node.js, React.js et Backbone.js.", text: "Ce sont des frameworks JavaScript qui fournissent une structure prête à l’emploi pour le code JavaScript, nous les utilisons donc pour accélérer le développement frontal. Par exemple, le framework AngularJS étend le HTML avec de nouveaux attributs, nécessite moins de code et aide à créer rapidement des applications. Node.js framework est utilisé pour la création de la couche d’interface back-end et permet d’optimiser l’évolutivité des applications." },
      "./assets/img/webdevws3.svg"
    ],
    WS4: [
      { title: "Conception UI/UX.", text: " La conception est ce qui donne vie au front-end, de sorte que chaque projet de développement d’applications Web nécessite un concepteur qualifié et créatif qui travaille harmonieusement avec un développeur front-end et résout professionnellement toute question liée à la conception." },
      { title: "Conception réactive et mobile.", text: "En raison du fait que de plus en plus d’utilisateurs accèdent à Internet à partir d’appareils mobiles, nous nous spécialisons dans la création de conceptions spécifiques aux mobiles. Nous prenons également en compte le fait qu’un site Web peut être visité à partir d’un bureau, de sorte que toutes les mises en page de nos sites Web peuvent s’adapter à n’importe quelle taille d’écran. Nos designs sont époustouflants." },
      { title: "Développement inter-navigateurs.", text: "Notre équipe assure la compatibilité entre les navigateurs de chaque site Web et veille à ce que les navigateurs interprètent correctement le code." },
      "./assets/img/webdevws4.svg"
    ],
    WS5: [
      { title: "Développement de systèmes de gestion de contenu.", text: "Nous construisons des systèmes de gestion de contenu pour vos besoins spécifiques. Nous travaillons avec des CMS populaires tels que WordPress, Joomla, Drupal et Magento." },
      { title: "Services de test.", text: "Ici, chez Wenovate, nous appliquons différents types de tests tels que les tests unitaires pour tester des parties individuelles du code, les tests de performance, les tests d’interface utilisateur (navigateur / fonctionnel) pour s’assurer qu’un site Web se comporte comme il se doit, et bien plus encore." },
      { title: "Services d’optimisation.", text: "" },
      { title: "Services de migration.", text: "" },
      { title: "Services de maintenance.", text: "" },
      "./assets/img/webdevws5.svg"
    ]
  },
  {
    name: "Maintenances",
    mainImg: "./assets/img/maintenances.png",
    mainText: "Chez Wenovate, le service de maintenance est en charge du parc informatique d'entreprises. Ce service a pour rôle la configuration de vos serveurs, veiller sur la sécurité de vos réseaux et données, la maintenance des postes informatiques et l'installation des logiciels et mises à jour.",
    OfferText: "Nous offrons des services complèts de maintenance. De la spécification des besoins au déploiement de la solution en passant par les tests unitaires et utilisateurs",
    YearExp: "4+",
    WS1: [
      { text: "Un niveau de maintenance informatique responsable" },
      { text: "Un niveau de maintenance informatique parfois nécessaire" },
      { text: "Un niveau de maintenance informatique ambitieux" },
      { text: "Réalisation régulière d’audit des performances et de la sécurité du système" },
      { text: "Sauvegarde régulière de vos données" },
      { text: "Effectuer des mises à jour logicielles" },

    ],
    WS2: [
      { title: "La maintenance préventive.", text: "C'est la solution la plus responsable, car elle évite les éventuelles catastrophes ultérieures, souvent dues à de la négligence." },
      { title: "La maintenance corrective.", text: "Consiste à intervenir a posteriori sur un système informatique défectueux." },
      { title: "La maintenance évolutive.", text: "Elle consiste par exemple à mettre à jour des systèmes d’exploitation, afin de bénéficier des nouveaux outils optimisés qu’ils proposent." },
      "./assets/img/maintenance.png"
    ],
  },
  {
    name: "IoT",
    mainImg: "./assets/img/iots.png",
    mainText:"L’IoT (Internet of Things) est un concept de système où tous ses objets sont interconnectés les uns aux autres via Internet. Tirant parti des capteurs intégrés, des processeurs et de différents moyens de communication - tels que BLE, Wi-Fi, iBeacon, NFC, RFID, etc. - les appareils liés peuvent collecter, relayer et agir sur les données partagées pour apporter de la valeur aux consommateurs et aux entreprises. En tant que société de développement de logiciels IoT, Wenovate aide les entreprises à lier leurs processus pour un service client intelligent et une prise de décision améliorée.",
    OfferText: "En tant que société de développement de logiciels IoT, Wenovate aide les entreprises à lier leurs processus pour un service client intelligent et une prise de décision améliorée.",
    YearExp: "3+",
    WS1: [
      { text: "Développement d’applications IoT personnalisées" },
      { text: "Programmation logicielle embarquée" },
      { text: "Développement de firmwares et d’API" },
      { text: "Développement de passerelles IoT" },
      { text: "Services de conseil IoT" },
      { text: "Analyse de données IoT" },
      { text: "Déploiement de serveurs cloud" },
      { text: "Solutions technologiques vocales" },
    ],
    WS2: [
      { title: "Développement de logiciels IoT.", text: "Impliqué dans une expertise IoT pratique depuis plus de 3 ans, Wenovate peut vous aider à développer un projet IoT à partir de zéro, à affiner une application déjà en cours d’exécution ou à intégrer votre solution dans une infrastructure existante." },
      { title: false, text: false },
      { title: false, text: false },
      "./assets/img/iot.jfif"
    ],
    WS5: [
      { title: "Maison et bureau intelligents", text: "" },
      { title: "Système de vente au détail", text: "" },
      { title: "Transport", text: "" },
      { title: "Banques et paiements", text: "" },
      { title: "Villes intelligentes", text: "" },
      "./assets/img/circle.png"
    ]
  },
  {
    name: "Développement Mobile",
    mainImg: "./assets/img/mobiledev.png",
    mainText: "Wenovate utilise des technologies de pointe pour fournir des applications mobiles fiables à ses clients. Notre expertise en tant que société de développement d’applications mobiles est prouvée par leurs critiques. En outre, nous sommes inclus dans les entreprises TOP.",
    OfferText: "Nous offrons des services complèts de developpement mobile. De la spécification des besoins au déploiement de la solution en passant par les tests unitaires et utilisateurs",
    YearExp: "3+",
    WS1: [
      { text: "Développement d’applications natives" },
      { text: "Développement d’applications multiplateformes (hybrides)" },
      { text: "Développement d’applications IoT" },
    ],
    WS2: [
      { title: "Développement d’applications natives.", text: "En utilisant les technologies défendues par Google et Apple, notre équipe mobile s’assure que votre application fonctionne au sommet des capacités de la plate-forme et a l’air de correspondre. Ils fonctionneront exceptionnellement bien sur un iPhone, un iPad ou l’un des appareils Android." },
      { title: false, text: false },
      { title: false, text: false },
      "./assets/img/mobiledev_android.svg",
      "./assets/img/mobiledev_ios.svg",
    ],
    WS3: [
      { title: "Développement d’applications multiplateformes (hybrides).", text: "Les technologies de développement hybrides vous permettent de créer une application qui fonctionnera à la fois sur Android et iOS. Avec la bonne approche, ils peuvent vous faire économiser jusqu’à 40% sur les coûts de développement sans compromettre l’interface utilisateur ou la vitesse de l’application." },
      { title: false, text: false },
      { title: false, text: false },
      "./assets/img/react-native.svg",
      // "./assets/img/xamarin.svg",
      "./assets/img/ionic.svg",
    ],
    WS4: [
      { title: "Développement d’applications IoT.", text: "Drones, machines industrielles, montres intelligentes ou pots de fleurs de haute technologie - l’équipe de développement mobile de Belitsoft peut écrire du code qui alimente les appareils IoT." },
      { title: false, text: false },
      { title: false, text: false },
      "./assets/img/iot.svg",
    ],
  },
  {
    name: "Base de données",
    mainImg: "./assets/img/sgbd.png",
    mainText: "Chaque application possède sa base de données qui stocke et gère des volumes massifs de données. Les données augmentent non seulement dans leur volume, mais aussi dans leur complexité. De nos jours, il est extrêmement important pour les entreprises de garder leurs données sûres et holistiques. Tous les flux de données doivent être optimisés. Des données bien structurées et leur utilisation appropriée future peuvent faciliter un meilleur service à la clientèle, le marketing et les ventes.",
    OfferText: "Notre société est spécialisée dans le développement de bases de données et crée des bases de données pour de grandes et petites quantités de stockage de données.",
    YearExp: "4+",
    WS1: [
      { text: "Conseil en bases de données" },
      { text: "Développement de bases de données personnalisées" },
      { text: "Développement de base de données clients" },
      { text: "Développement de base de données marketing" },
      { text: "Développement de base de données d’inventaire" },
      { text: "Développement de base de données de patients" },
      { text: "Développement de base de données des employés" },
      { text: "Solutions logicielles de base de données analytiques" },
    ],
    WS2: [
      { title: "Conseil en bases de données.", text: "Si vous avez besoin d’un partenaire professionnel de développement de base de données pour discuter de toute question concernant votre idée, nos experts se feront un plaisir de vous aider et de discuter de vos besoins de développement de base de données avec vous. Nous vous contacterons dès que possible après que vous nous aurez envoyé votre demande." },
      { title: "Conception de la base de données.", text: false },
      { title: "Test de base de données.", text: "Notre équipe d’assurance qualité applique toujours des tests de performance pour augmenter l’efficacité et la vitesse de fonctionnement de la base de données. Nous proposons également des services d’optimisation, de débogage, de dépannage et de surveillance des bases de données pour résoudre les problèmes de performances." },
      "./assets/img/bddws2.png"
    ],
    WS3: [
      { title: "Développement de bases de données personnalisées. Analyse et rapports de bases de données.", text: false },
      { title: "Intégration de l’appareil.", text: "Nous pouvons intégrer votre base de données dans n’importe quel smartphone ou tablette." },
      { title: "Intégration de plusieurs sources de données. Modernisation et mise à niveau des bases de données.", text: false },
      "./assets/img/bddws3.jfif"
    ],
    WS4: [
      { title: "Solutions logicielles de base de données distribuées. Solutions logicielles de base de données externes", text: false },
      { title: "Solutions logicielles de base de données analytiques. Solutions logicielles de base de données opérationnelles", text: false },
      { title: "Logiciel de base de données d’équipement. Logiciel de base de données de télécommunications", text: false },
      "./assets/img/bddws4.png"
    ],
  },
];

export function getServices() {
  return servicesList;
}
export function getServiceItem(name) {
  return servicesList.find(item => item.name === name);
}